import { difference, intersection, sortBy } from 'lodash'
import { Response, Set } from './types'

export function formatResponse(
  response: Response,
  set: Set,
  cube?: { id: string; cards?: string[] },
) {
  const cards = Object.entries(response.ratings).map(
    ([collectorNumber, rating]) => {
      const cardName = set.cards[collectorNumber]
      const inCube = cube?.cards?.includes(cardName)

      return {
        name: cardName,
        inCube,
        rating,
      }
    },
  )

  const sortedCards = sortBy(cards, (card) => -card.rating)

  const unratedCards = difference(
    intersection(Object.values(set.cards), cube?.cards ?? []),
    cards.map((card) => card.name),
  )

  return {
    response,
    set,
    cube,
    cards: [
      ...sortedCards,
      ...unratedCards.map((name) => ({ name, inCube: true, rating: null })),
    ],
  }
}
