import { cubeIDFromLink, fetchCube } from 'utils/cube-cobra'
import { fetchCollection } from 'utils/scryfall'

export type Cube = NonNullable<Awaited<ReturnType<typeof fetchCubeWithCards>>>

const cache = {} as Record<string, Promise<Cube | null>>

async function fetchCubeWithCards(cubeID: string) {
  const cube = await fetchCube(cubeID).catch(() => null)

  if (!cube?.cards || !cube?.id) {
    return null
  }

  const response = await fetchCollection(
    cube.cards.mainboard.map((card) => ({ id: card.cardID })),
  )

  return {
    id: cube.id,
    name: cube.name,
    imageURI: cube.image.uri,
    imageName: cube.image.imageName,
    imageArtist: cube.image.artist,
    cards: response.cards.map((card) => card.name),
  }
}

/**
 * Returns meta data about a cube from a Cube Cobra link and a list of the names
 * of cards in the cube. Caches values in memory.
 */
export async function getCube(cubeLink: string) {
  const cubeID = cubeIDFromLink(cubeLink)

  if (cubeID == null) {
    return null
  }

  if (cache[cubeID] == null) {
    cache[cubeID] = fetchCubeWithCards(cubeID)
  }

  return cache[cubeID]
}
