import * as styles from './ProspectiveArchive.module.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { compact, uniqBy } from 'lodash'

import { TextButton } from 'components/controls'
import * as Forms from 'components/forms'

import { cubeIDFromLink } from 'utils/cube-cobra'
import { isPresentString } from 'utils/isPresentString'
import { usePersistentState } from 'utils/usePersistentState'

import { ProspectiveArchiveData } from './data/types'
import { filterResponses } from './data/filterResponses'
import { Cube, getCube } from './data/fetchCardsInCube'
import { formatResponse } from './data/useData'
import { identifierForResponse } from './data/identifierForResponse'
import { downloadRatings } from './data/downloadCSV'

import { Filters } from './Filters'
import { Histogram } from './Histogram'
import { Response } from './Response'

interface Props {
  data: ProspectiveArchiveData
}

export const ProspectiveArchive: React.FC<Props> = (props) => {
  const { data } = props

  const { sets } = data

  const [query, setQuery] = usePersistentState('prospective-survey-query', '')

  const responses = useMemo(() => filterResponses(data, query), [data, query])

  const [cubes, setCubes] = useState<
    Record<string, Cube | { id: string; error: string }>
  >({})

  useEffect(() => {
    for (const result of responses) {
      const id = cubeIDFromLink(result.cubeLink)

      if (isPresentString(id)) {
        getCube(result.cubeLink).then((cube) =>
          setCubes((value) => ({
            ...value,
            [id]: cube ?? { id, error: `Failed to fetch cube with id: ${id}` },
          })),
        )
      }
    }
  }, [responses])

  const formattedResponses = useMemo(() => {
    return responses.map((response) => {
      const cubeID = cubeIDFromLink(response.cubeLink)
      return formatResponse(
        response,
        sets[response.set],
        cubeID != null ? cubes[cubeID] : undefined,
      )
    })
  }, [cubes, responses, sets])

  const cubesInResults = useMemo(() => {
    return uniqBy(
      compact(formattedResponses.map(identifierForResponse)),
      (cube) => cube.id,
    )
  }, [formattedResponses])

  const [selectedCubes, setSelectedCubes] = useState<Record<string, boolean>>(
    {},
  )

  useEffect(() => {
    setSelectedCubes(
      cubesInResults.reduce((result, cube) => {
        result[cube.id] = true
        return result
      }, {} as Record<string, boolean>),
    )
  }, [cubesInResults])

  const filteredResponses = useMemo(() => {
    return formattedResponses.filter(
      (response) => selectedCubes[identifierForResponse(response).id],
    )
  }, [formattedResponses, selectedCubes])

  const totalRatingCount = useMemo(() => {
    return filteredResponses.flatMap((response) =>
      response.cards.filter((card) => card.rating != null),
    ).length
  }, [filteredResponses])

  const canShowFilters = useMemo(
    () => cubesInResults.length > 1,
    [cubesInResults],
  )
  const [showFilters, setShowFilters] = useState(false)

  const download = useCallback(() => {
    downloadRatings(filteredResponses)
  }, [filteredResponses])

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <Forms.LargeLabel>Username</Forms.LargeLabel>

        {/* Hidden field to prevent Safari from adding auto complete to the real field */}
        <input
          type="text"
          name="name"
          className={styles.safariNameTrap}
        ></input>

        <div className={styles.searchField}>
          <Forms.TextInput
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
            placeholder="Username"
            maxLength={64}
          />
          <Forms.Hint>Search by username or names comma-separated</Forms.Hint>
        </div>

        {canShowFilters && showFilters && (
          <Filters
            cubes={cubesInResults}
            selectedCubes={selectedCubes}
            setSelectedCubes={setSelectedCubes}
          />
        )}

        {filteredResponses.length > 0 && (
          <div className={styles.summary}>
            <div>
              <div>
                {filteredResponses.length} Responses with {totalRatingCount}{' '}
                cards rated
              </div>
              <div>
                <TextButton onClick={download}>Download CSV</TextButton>
                {canShowFilters && (
                  <>
                    {' '}
                    -{' '}
                    <TextButton
                      onClick={() => setShowFilters((value) => !value)}
                    >
                      {showFilters ? 'Hide Filters' : 'Filter by Cube'}
                    </TextButton>
                  </>
                )}
              </div>
            </div>

            <Histogram responses={filteredResponses} />
          </div>
        )}
      </div>

      {filteredResponses.length > 0 && (
        <div>
          {filteredResponses.map((response, index) => (
            <Response key={index} {...response} />
          ))}
        </div>
      )}
    </div>
  )
}
