import * as d3 from 'd3'

import { downloadCSV } from 'utils/downloadCSV'

import { Set, Response } from './types'

export function downloadRatings(
  responses: {
    response: Response
    set: Set
  }[],
) {
  downloadCSV(
    d3.csvFormat(
      responses.map((r) => {
        const ratings = Object.entries(r.response.ratings)
          .map(([collectorNumber, rating]) => {
            return `${rating}: ${r.set.cards[collectorNumber]}`
          })
          .join('\n')

        return {
          ...r.response,
          ratings,
        }
      }),
      [
        'set',
        'username',
        'cubeType',
        'cubeLink',
        'ratings',
        'additionalComments',
      ],
    ),
    'cube-prospective-survey-results',
  )
}
