import { sortBy } from 'lodash'

import { ProspectiveArchiveData } from './types'

export function filterResponses(data: ProspectiveArchiveData, query: string) {
  const { sets, responses } = data

  if (query.length <= 2) {
    return []
  }

  const queryOptions = [
    query.toLowerCase(),
    ...query
      .toLowerCase()
      .split(',')
      .map((v) => v.replace(/\s/g, ''))
      .filter((v) => v.length > 0),
  ]

  const result = responses.filter((response) => {
    return queryOptions.includes(
      response.username.toLowerCase().replace(/\s/g, ''),
    )
  })

  return sortBy(result, (r) => {
    return new Date(sets[r.set].releaseDate)
  }).reverse()
}
