import * as styles from './TextInput.module.scss'

import React from 'react'

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const TextInput: React.FC<Props> = (props) => {
  return <input className={styles.input} type="text" {...props} />
}

export default TextInput
