import * as styles from './Checkbox.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

const Checkbox: React.FC<Props> = (props) => {
  const { className, ...checkboxProps } = props

  return (
    <input
      type="checkbox"
      className={classNames(styles.checkbox, className)}
      {...checkboxProps}
    />
  )
}

export default Checkbox
