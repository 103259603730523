import * as styles from './TextArea.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

const TextArea: React.FC<Props> = (props) => {
  const { className, style = {}, rows = 5, ...textAreaProps } = props

  return (
    <textarea
      className={classNames(styles.textArea, className)}
      style={style}
      rows={rows}
      {...textAreaProps}
    />
  )
}

export default TextArea
