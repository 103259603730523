import * as styles from './Filters.module.scss'

import React from 'react'

import { isPresentString } from 'utils/isPresentString'

import * as Forms from 'components/forms'

import { Cube } from './data/fetchCardsInCube'

interface Props {
  cubes: (
    | Cube
    | {
        id: string
        name?: string
      }
  )[]
  selectedCubes: Record<string, boolean>
  setSelectedCubes: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >
}

export const Filters: React.FC<Props> = (props) => {
  const { cubes, selectedCubes, setSelectedCubes } = props

  return (
    <div className={styles.container}>
      {cubes.length > 1 && (
        <div className={styles.cubes}>
          {cubes.map((cube) => (
            <label key={cube.id}>
              <Forms.Checkbox
                checked={selectedCubes[cube.id] === true}
                onChange={(event) =>
                  setSelectedCubes((value) => ({
                    ...value,
                    [cube.id]: event.target.checked,
                  }))
                }
              />{' '}
              {isPresentString(cube.name) ? (
                cube.name
              ) : (
                <span className={styles.missingName}>Unknown Cube</span>
              )}
            </label>
          ))}
        </div>
      )}
    </div>
  )
}
