import { cubeIDFromLink } from 'utils/cube-cobra'

/**
 * For a result, including the response and associated cube info, return an
 * identifier and name.
 *
 * The id will either be the unique id of the Cube on Cube Cobra, the inferred
 * ID from the link to the cube in the survey response, or the full cube link if
 * it doesn't match the pattern of a Cube Cobra url.
 */
export function identifierForResponse(result: {
  cube?: { id: string; name?: string; error?: string }
  response: { cubeLink: string }
}): { id: string; name?: string } {
  if (result.cube && !result.cube.error) {
    return result.cube
  }

  const id = cubeIDFromLink(result.response.cubeLink)

  if (id) {
    return { id, name: id }
  }

  return {
    id: result.response.cubeLink,
    name: result.response.cubeLink,
  }
}
