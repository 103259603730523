import * as styles from './RangeInput.module.scss'

import React from 'react'
import classnames from 'classnames'

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'value'
  > {
  min: number
  max: number
  value: number | null
}

const RangeInput: React.FC<Props> = (props) => {
  const { value, ...inputProps } = props

  return (
    <input
      type="range"
      className={classnames(styles.input, {
        'RangeInput--withValue': props.value != null,
      })}
      {...inputProps}
      value={value || (props.min + props.max) / 2}
    />
  )
}

export default RangeInput
