// title: Search Set Prospective Submissions
// frameCardName: Grapple with the Past
// frameCardSet: EMN

import * as styles from './archive-search.module.scss'

import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import Indicator from 'components/loading/Indicator'

import { ProspectiveArchiveData } from 'components/prospective-archive/data/types'
import { ProspectiveArchive } from 'components/prospective-archive/ProspectiveArchive'

interface Props {
  pageContext: any
}

const ProspectiveSurveyArchiveSearchPage: React.FC<Props> = (props) => {
  const [data, setData] = useState<ProspectiveArchiveData | null>(null)

  useEffect(() => {
    fetch('/prospective-archive.json')
      .then((response) => response.json())
      .then((responseData) => setData(responseData))
  }, [])

  return (
    <Layout {...props.pageContext} accentColor="#CA9C42">
      <article>
        <Typography.SecondaryHeading>
          Prospective Survey Archive Search
        </Typography.SecondaryHeading>

        <Typography.Paragraph>
          Search past <Link to="/set-prospectives/">set prospective</Link>{' '}
          surveys for first-impression card ratings and to see what cards have
          remained, been cut, or been added that weren&rsquo;t initially
          included in the survey.
        </Typography.Paragraph>

        {data ? (
          <ProspectiveArchive data={data} />
        ) : (
          <div className={styles.loadingIndicator}>
            <Indicator />
          </div>
        )}
      </article>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Prospective Survey Archive Search',
        description:
          "Search past submissions to Lucky Paper's Cube set surveys. See what cards you rated are still in your Cubes.",
      }}
    />
  )
}

export default ProspectiveSurveyArchiveSearchPage
