import * as styles from './CopyButton.module.scss'

import React, { useEffect, useState } from 'react'

import { copyToClipboard } from 'utils/copyToClipboard'

interface Props {
  copyText: string
  children: string
}

const CheckIcon: React.FC = () => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      className={styles.check}
    >
      <polyline
        style={{ fill: 'none', stroke: '#1b2433', strokeWidth: 3 }}
        points="6.8,15.2 13.1,20.4 22.4,9.4"
      />
    </svg>
  )
}

export const CopyButton: React.FC<Props> = (props) => {
  const { copyText, children } = props

  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) {
      return
    }

    const timeout = setTimeout(() => {
      setCopied(false)
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [copied])

  const copy = () => {
    copyToClipboard(copyText)
    setCopied(true)
  }

  return (
    <button type="button" className={styles.button} onClick={copy}>
      {copied ? (
        <>
          <CheckIcon /> Copied to Clipboard
        </>
      ) : (
        children
      )}
    </button>
  )
}
