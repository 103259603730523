import * as styles from './LargeLabel.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
  htmlFor?: string
}

const LargeLabel: React.FC<Props> = (props) => {
  const { children, htmlFor } = props

  return (
    <label className={styles.label} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

export default LargeLabel
