import * as styles from './Histogram.module.scss'

import React, { useMemo } from 'react'
import { clamp, range } from 'lodash'
import * as d3 from 'd3'

import { EdgeInsets, Rect } from 'utils/geometry'

interface Props {
  responses: {
    cards: {
      name: string
      inCube?: boolean
      rating: number | null
    }[]
  }[]
}

const axisInsets = new EdgeInsets(0, 0, 0, 0)
const bounds = new Rect(0, 0, 300, 120)
const contentRect = bounds.inset(axisInsets)

export const Histogram: React.FC<Props> = (props) => {
  const { responses } = props

  const bins = useMemo(() => {
    const cards = responses.flatMap((c) =>
      c.cards.filter((card) => card.rating != null),
    )

    return cards.reduce(
      (result, card) => {
        const key =
          card.inCube === true
            ? 'in'
            : card.inCube === false
            ? 'out'
            : 'unknown'
        if (card.rating !== null) {
          const bin = clamp(Math.floor(card.rating), 0, 9)
          result[bin][key]++
          result[bin].total++
        }
        return result
      },
      range(0, 10).map(() => ({ in: 0, out: 0, unknown: 0, total: 0 })),
    )
  }, [responses])

  const maxX = useMemo(() => bins.length - 1, [bins])
  const maxY = useMemo(() => Math.max(...bins.map((bin) => bin.total)), [bins])

  const barWidth = useMemo(() => contentRect.width / (maxX + 1) - 5, [maxX])

  const scaleX = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, maxX])
        .range([contentRect.x, contentRect.maxX - barWidth]),
    [barWidth, maxX],
  )

  const scaleY = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, maxY])
        .range([contentRect.y, contentRect.maxY]),
    [maxY],
  )

  return (
    <svg
      viewBox={`0 0 ${bounds.width} ${bounds.height}`}
      className={styles.svg}
    >
      {bins.map((datum, index) => (
        <React.Fragment key={index}>
          {datum.unknown > 0 && (
            <rect
              x={scaleX(index)}
              y={contentRect.height - scaleY(datum.unknown)}
              width={barWidth}
              height={scaleY(datum.unknown)}
              fill="#808080"
              stroke="#ffffff"
              strokeWidth={2}
            />
          )}
          {datum.in > 0 && (
            <rect
              x={scaleX(index)}
              y={contentRect.height - scaleY(datum.in) - scaleY(datum.unknown)}
              width={barWidth}
              height={scaleY(datum.in)}
              fill="#538f25"
              stroke="#ffffff"
              strokeWidth={2}
            />
          )}
          {datum.out > 0 && (
            <rect
              x={scaleX(index)}
              y={
                contentRect.height -
                scaleY(datum.out) -
                scaleY(datum.in) -
                scaleY(datum.unknown)
              }
              width={barWidth}
              height={scaleY(datum.out)}
              fill="#c53933"
              stroke="#ffffff"
              strokeWidth={2}
            />
          )}
        </React.Fragment>
      ))}
    </svg>
  )
}
